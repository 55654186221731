<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('refunds_report')"
                >
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('refunds_report')"
                >
                </HeaderMobile>
            </template>
            <div class="row">
                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('period')">
                        <payment-periods-selectbox
                            v-model="form.period_id">
                        </payment-periods-selectbox>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('product')">
                        <payment-products-selectbox
                            v-model="form.product_id">
                        </payment-products-selectbox>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox v-model="form.faculty_code"></faculty-selectbox>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('program')">
                        <program-selectbox v-model="form.program_code" :faculty_code="form.faculty_code"></program-selectbox>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('student_number')">
                        <b-input type="text"
                                 v-model="form.number">
                        </b-input>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('national_id')">
                        <b-input type="text"
                                 v-model="form.national_id">
                        </b-input>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('payment_type_group')">
                        <payment-type-type-selectbox
                            v-model="form.payment_type">
                        </payment-type-type-selectbox>
                    </b-form-group>
                </div>

                <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                    <b-form-group :label="$t('payment_type')">
                        <payment-types-selectbox
                            :type="form.payment_type"
                            v-model="form.payment_type_id">
                        </payment-types-selectbox>
                    </b-form-group>
                </div>

                <div class="col-12">
                    <b-row>
                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('transaction_start_date')">
                                <b-input type="date"
                                         v-model="form.start_date">
                                </b-input>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('transaction_end_date')">
                                <b-input type="date"
                                         v-model="form.end_date">
                                </b-input>
                            </b-form-group>
                        </div>
                    </b-row>
                </div>
                <div class="col-12">
                    <b-row>
                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('refund_date_start')">
                                <b-input type="date"
                                         v-model="form.refund_date_start">
                                </b-input>
                            </b-form-group>
                        </div>

                        <div class="col-6 col-md-6 col-xl-6 col-xxl-6">
                            <b-form-group :label="$t('refund_date_end')">
                                <b-input type="date"
                                         v-model="form.refund_date_end">
                                </b-input>
                            </b-form-group>
                        </div>
                    </b-row>
                </div>

                <div class="col-12 col-sm-6">
                    <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('report_type')">
                            <multi-selectbox :allow-empty="false" :multiple="false"
                                             :validate-error="errors[0]" :options="responseTypeOptions"
                                             v-model="form.response_type"></multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <processing-button @click="report" label="get_report"
                                       :processing="processing"></processing-button>
                </div>

            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import PaymentProductsSelectbox from "@/components/interactive-fields/PaymentProductsSelectbox";
import PaymentPlanTypesSelectbox from "@/components/interactive-fields/PaymentPlanTypesSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import PaymentProgramLevelSelectbox from "@/components/interactive-fields/PaymentProgramLevelSelectbox";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import RefundService from "@/services/RefundService";
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import PaymentTypeTypeSelectbox from "@/components/interactive-fields/PaymentTypeTypeSelectbox";
import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
import TrueFalseSelectbox from "@/components/interactive-fields/TrueFalseSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import ProcessingButton from "@/components/elements/ProcessingButton";
import qs from "qs";

export default {
    components: {
        ProcessingButton,
        MultiSelectbox,
        TrueFalseSelectbox,
        PaymentTypesSelectbox,
        PaymentTypeTypeSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        ValidationProvider,
        ValidationObserver,
        PaymentPeriodsSelectbox,
        PaymentProductsSelectbox,
        PaymentPlanTypesSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        PaymentProgramLevelSelectbox,
        SemestersSelectbox,
        ParameterSelectbox,
        DatatableFilter,
        Datatable
    },
    data() {
        return {
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
            ],
            form: {response_type:'page'},
            processing:false
        }
    },
    metaInfo() {
        return {
            title: this.$t("refunds_report")
        }
    },
    methods: {
        async report() {
            let config = {
                params: {
                    filter:this.form
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            let isArrayBuffer=false;
            if (this.form.response_type == 'excel') {
                config.responseType = 'arraybuffer';
                isArrayBuffer=false;
            }
            this.processing=true;
            RefundService.report(config)
                .then(response=>{
                    if (this.form.response_type == 'page') {
                        this._openFile(response);
                    } else if (this.form.response_type == 'excel') {
                        this._downloadFile(response, 'Tahsilat Raporu.xlsx');
                    }
                })
                .catch(e => {
                    this.showErrors(e, null, isArrayBuffer);
                })
                .finally(()=>{this.processing=false});
        },
    }
}
</script>
